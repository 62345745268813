import EndPointConfig from '../../../utils/endPointConfig';
import { collection, doc, setDoc, Bytes } from 'firebase/firestore';
import { db } from './../../../fireStoreConfig/fireStore';
import { decodeAccessToken } from './../../../utils/helper';

const getformMeta = () => {
  const userDetails = decodeAccessToken(sessionStorage.getItem('accessToken'));
  const desigName: any = sessionStorage.getItem('desigName');
  const userId = sessionStorage.getItem('userId');
  const teamId = sessionStorage.getItem('teamId');
  const userProfile = {
    user_id: userId,
    user_alias: userDetails && userDetails['udn'] ? userDetails['udn'] : null,
    desig_id: userDetails && userDetails['did'] ? userDetails['did'] : null,
    desig_name: desigName ? desigName : null,
  };
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userAgent = navigator.userAgent;
  return { teamId, userProfile, timeZone, userAgent };
};

const getformMetaForGuestuser = () => {
  const userDetails = decodeAccessToken(sessionStorage.getItem('accessToken'));
  const desigName: any = '_mo_guest_ops';
  const userId = userDetails && userDetails['uid'] ? userDetails['uid'] : '';
  const teamId = userDetails && userDetails['aid'] ? userDetails['aid'] : '';
  const userProfile = {
    user_id: userId,
    user_alias: userDetails && userDetails['udn'] ? userDetails['udn'] : '',
    desig_id: userDetails && userDetails['did'] ? userDetails['did'] : '',
    desig_name: desigName ? desigName : '',
  };
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userAgent = navigator.userAgent;
  return { teamId, userProfile, timeZone, userAgent };
};

export async function callWithTimeout(promise: any, time = 12000) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject('timeout');
    }, time);
    promise.then(
      (res: any) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err: any) => {
        clearTimeout(timeoutId);
        reject(err);
      }
    );
  });
}

export async function getAllMyReports(data: any, header: any) {
  const url = EndPointConfig.Reports.getAllMyReports;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getInboxData(data: any, header: any) {
  const url = EndPointConfig.Reports.getInboxData(
    data.templateId,
    data.instanceIds
  );

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getReportDetails(data: any, header: any) {
  const url = EndPointConfig.Reports.getReportDetails(
    data.templateId,
    data.teamId
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getFormDataDetails(data: any, header: any) {
  const url = EndPointConfig.Reports.getFormDataDetails(
    data.templateId,
    data.instanceId
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getRatingFormDataDetails(data: any, header: any) {
  const url = EndPointConfig.Reports.getRatingFormDataDetails(
    data.templateId,
    data.instanceId
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function createFormData(data: any, header: any) {
  const url = EndPointConfig.Reports.createFormData(data.meta.template_id);
  const formMeta = data.isFromGuestUser
    ? getformMetaForGuestuser()
    : getformMeta();
  try {
    data['meta']['team_id'] = formMeta.teamId;
    data['meta']['user_profile'] = formMeta.userProfile;
    data['meta']['time_zone'] = formMeta.timeZone;
    data['meta']['user_agent'] = formMeta.userAgent;
    data['meta']['status'] = 'PENDING';
    data['meta']['view_name'] = '';
    delete data['isFromGuestUser'];

    const response: any = await callWithTimeout(
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: header,
      })
    );
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    if (e === 'timeout') {
      return {
        status: true,
        data: [],
        timeout: e,
      };
    } else {
      console.error(e);
      throw e;
    }
  }
  // try {
  //   data['meta']['status'] = 'PENDING';
  //   data['meta']['view_name'] = '';

  //   const firestoreRef = db.collection('formdatasync');
  //   const addRef: any = await firestoreRef.add(data);

  //   if (addRef && !addRef.id) {
  //     return {
  //       status: false,
  //       error: 'Firestore record save error',
  //     };
  //   }
  //   return {
  //     status: true,
  //     data: data,
  //   };
  // } catch (e) {
  //   console.error(e);
  //   throw e;
  // }
}

export async function updateFormData(data: any, header: any) {
  const url = EndPointConfig.Reports.updateFormData(
    data.meta.template_id,
    data.payload._mo_instance_id
  );
  const formMeta = getformMeta();
  try {
    data['meta']['team_id'] = formMeta.teamId;
    data['meta']['user_profile'] = formMeta.userProfile;
    data['meta']['time_zone'] = formMeta.timeZone;
    data['meta']['user_agent'] = formMeta.userAgent;
    data['meta']['status'] = 'PENDING';

    const response: any = await callWithTimeout(
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: header,
      })
    );
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    if (e === 'timeout') {
      return {
        status: true,
        data: [],
        timeout: e,
      };
    } else {
      console.error(e);
      throw e;
    }
  }
  // try {
  //   data['meta']['status'] = 'PENDING';
  //   const firestoreRef = db.collection('formdatasync');
  //   const addRef: any = await firestoreRef.add(data);

  //   if (addRef && !addRef.id) {
  //     return {
  //       status: false,
  //       error: 'Firestore record save error',
  //     };
  //   }
  //   return {
  //     status: true,
  //     data: data,
  //   };
  // } catch (e) {
  //   console.error(e);
  //   throw e;
  // }
}

export async function getLookupInstanceIds(data: any, header: any) {
  const url = EndPointConfig.Reports.getLookupInstanceIds(
    data.sourceTemplateId,
    data.referenceTemplateId,
    data.fieldInternalName,
    data.query
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getExternalLookupData(data: any, header: any) {
  const url = EndPointConfig.Reports.getExternalLookupData(
    data.templateId,
    data.instanceIds
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getCatalogData(data: any, header: any) {
  const url = EndPointConfig.Reports.getCatalogData(data.catalogDetails);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getInboxInstanceIds(data: any, header: any) {
  const url = EndPointConfig.Reports.getInboxInstanceIds(
    data.templateId,
    data.query,
    data.filterQuery
  );

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getUnassignedData(data: any, header: any) {
  const url = EndPointConfig.Reports.getUnassignedData(
    data.templateId,
    data.query,
    data.stageName,
    data.offset,
    data.pageSize
  );

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function updateAssignee(data: any, header: any) {
  const url = EndPointConfig.Reports.updateAssignee(data.details.templateId);
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify({ documents: data.details.documents }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getFirebaseImage(data: any, header: any) {
  const signedUrl = EndPointConfig.Reports.getSignedUrl(
    data.filepath,
    data.contentType,
    data.action
  );
  try {
    const response = await fetch(signedUrl, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getBlobUrl(action: any) {
  try {
    const blobUrl = await fetch(action.url, {
      headers: { 'Content-Type': action.contentType },
    })
      .then((response1: any) => {
        return response1.blob();
      })
      .then((blob: any) => {
        return URL.createObjectURL(blob);
      });
    return {
      data: blobUrl,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getAllInboxConfigs(data: any, header: any) {
  const url = EndPointConfig.Reports.getAllInboxConfigs(data.templateId);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getInboxViewsData(data: any, header: any) {
  try {
    const { templateId, viewName, queryString, pageNumber, search, pageSize } =
      data;
    const url = EndPointConfig.Reports.getInboxViewsData(templateId, viewName);
    let bodyObj: any = {
      filter: queryString,
      page_number: pageNumber,
      page_size: pageSize,
    };
    if (search) {
      bodyObj = { ...bodyObj, search };
    }
    const response: any = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyObj),
      headers: header,
    });
    const total_count = JSON.parse(response.headers.get('total-count'));
    return {
      status: response.ok,
      data: await response.json(),
      total_count,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function createVendorOnboardingForm(data: any, header: any) {
  const url = EndPointConfig.Reports.createVendorOnboardingForm;
  try {
    const response: any = await fetch(url, {
      method: 'POST',
      body: data,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    if (e === 'timeout') {
      return {
        status: true,
        data: [],
        timeout: e,
      };
    } else {
      console.error(e);
      throw e;
    }
  }
}

export async function saveAttachment(data: any) {
  try {
    data['payload']['byte_array'] = Bytes.fromUint8Array(
      data['payload']['byte_array']
    );
    data['meta']['status'] = 'PENDING';
    const docRef = doc(collection(db, 'formdatamediasync'));
    data['firestore_id'] = docRef.id;
    return new Promise((resolve, reject) => {
      setDoc(docRef, data)
        .then(() => {
          resolve({
            status: true,
            data: data,
          });
        })
        .catch((error) => {
          reject({
            status: false,
            data: data,
          });
        });
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function createFormDataFirebase(data: any) {
  try {
    const formMeta = data.isFromGuestUser
      ? getformMetaForGuestuser()
      : getformMeta();

    data['meta']['team_id'] = formMeta.teamId;
    data['meta']['user_profile'] = formMeta.userProfile;
    data['meta']['time_zone'] = formMeta.timeZone;
    data['meta']['user_agent'] = formMeta.userAgent;
    data['meta']['status'] = 'PENDING';
    delete data['isFromGuestUser'];

    const firestoreRef = doc(collection(db, 'formdatasync'));

    return new Promise((resolve, reject) => {
      setDoc(firestoreRef, data)
        .then(() => {
          resolve({
            status: true,
            data: data,
          });
        })
        .catch((error) => {
          reject({
            status: false,
            data: data,
          });
        });
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getAccessTokenForGuestUser(
  data: any,
  header: any
): Promise<object> {
  const url = EndPointConfig.guestUser.token;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getOTPForGuestUser(data: any, header: any) {
  const url = EndPointConfig.guestUser.getOtp;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        user_name: data.userName,
        user_name_type: data.loginType,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function validateGuestUserOTP(data: any, header: any) {
  const url = EndPointConfig.guestUser.validateGuestUserOTP;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        request_id: data.requestId,
        otp_code: data.otp,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function resendGuestUserOTP(data: any, header: any) {
  const url = EndPointConfig.guestUser.resendOTP;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        request_id: data.requestId,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getAuthorizedAccessTokenForGuestUser(
  data: any,
  header: any
): Promise<object> {
  const url = EndPointConfig.guestUser.authorizedToken;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getActivityLog(data: any, header: any) {
  let url = EndPointConfig.Reports.getActivityLog(
    data.templateId,
    data.instanceId
  );
  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getTeamDesignations(data: any, header: any) {
  const url = EndPointConfig.Teams.getTeamDesignations(data.teamId);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function createNaveenFormData(data: any, header: any) {
  const url = EndPointConfig.Reports.createFormData(data.meta.template_id);
  const formMeta = getformMetaForGuestuser();
  try {
    data['meta']['team_id'] = formMeta.teamId;
    data['meta']['user_profile'] = formMeta.userProfile;
    data['meta']['time_zone'] = formMeta.timeZone;
    data['meta']['user_agent'] = formMeta.userAgent;
    data['meta']['status'] = 'PENDING';
    data['meta']['view_name'] = '';

    const response: any = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    if (e === 'timeout') {
      return {
        status: true,
        data: [],
        timeout: e,
      };
    } else {
      console.error(e);
      throw e;
    }
  }
}

export async function getAuthorizedAccessTokenForSubmitForm(
  data: any,
  header: any
): Promise<object> {
  const url = EndPointConfig.guestUser.accessToken;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function updateNaveenFormData(data: any, header: any) {
  const url = EndPointConfig.Reports.updateFormData(
    data.meta.template_id,
    data.payload._mo_instance_id
  );
  const formMeta = getformMetaForGuestuser();
  try {
    data['meta']['team_id'] = formMeta.teamId;
    data['meta']['user_profile'] = formMeta.userProfile;
    data['meta']['time_zone'] = formMeta.timeZone;
    data['meta']['user_agent'] = formMeta.userAgent;
    data['meta']['status'] = 'PENDING';

    const response: any = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    if (e === 'timeout') {
      return {
        status: true,
        data: [],
        timeout: e,
      };
    } else {
      console.error(e);
      throw e;
    }
  }
}

export async function updateRatingFormData(data: any, header: any) {
  const url = EndPointConfig.Reports.updateFormData(
    data.meta.template_id,
    data.payload._mo_instance_id
  );
  const formMeta = getformMetaForGuestuser();
  try {
    data['meta']['team_id'] = formMeta.teamId;
    data['meta']['user_profile'] = formMeta.userProfile;
    data['meta']['time_zone'] = formMeta.timeZone;
    data['meta']['user_agent'] = formMeta.userAgent;
    data['meta']['status'] = 'PENDING';

    const response: any = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    if (e === 'timeout') {
      return {
        status: true,
        data: [],
        timeout: e,
      };
    } else {
      console.error(e);
      throw e;
    }
  }
}

export async function orderPay(data: any, header: any) {
  const url = EndPointConfig.guestUser.order;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        instance_id: data.instanceId,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function paymentConfirm(data: any, header: any) {
  const url = EndPointConfig.guestUser.paymentConfirm;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        instance_id: data.instanceId,
        order_id: data.orderId,
        transaction_id: data.transactionId,
        razorpay_signature: data.razorpaySignature,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getTeamDetails(data: any, header: any) {
  const url = EndPointConfig.guestUser.getTeamDetails(data.teamId);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}

export async function getFirebaseTeamImage(data: any, header: any) {
  const signedUrl = EndPointConfig.guestUser.getTeamSignedUrl(
    data.filepath,
    data.contentType
  );
  try {
    const response = await fetch(signedUrl, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function irctcOrderPay(data: any, header: any) {
  const url = EndPointConfig.guestUser.irctcOrder;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        instance_ids: data.instanceIds,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function irctcPaymentConfirm(data: any, header: any) {
  const url = EndPointConfig.guestUser.irctcPaymentConfirm;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        instance_ids: data.instanceIds,
        order_id: data.orderId,
        payment_id: data.transactionId,
        razorpay_signature: data.razorpaySignature,
        template_id: data.templateId,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getIrctcCardList(data: any, header: any) {
  const url = EndPointConfig.guestUser.getIrctcCardList(
    data.desigId,
    data.templateId
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getOTPForForm(data: any, header: any) {
  let url = EndPointConfig.UserOnboarding.getOTPForForm;
  try {
    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        user_name: data.userName,
        user_name_type: data.loginType,
      }),
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getNeProductFilterOptions(data: any, header: any) {
  const url = EndPointConfig.Reports.getNeProductFilter(
    data.templateId,
    data.filterQuery
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    throw e;
  }
}
