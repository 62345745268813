import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetRequestFalse } from '../actions/actions';
import OtpCard from '../components/otpCard';
import '../styles/customLogin.css';
import withRouter from 'withRouter';

interface IProps {
  resetRequestFalse: () => void;
  router: any;
  teamDetails: any;
}

interface IState {
  currentImageIndex: number;
  imagesLoaded: boolean;
}

class customOtp extends Component<IProps, IState> {
  private intervalId: NodeJS.Timeout | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      imagesLoaded: false,
    };
  }
  
  componentDidMount() {
    this.props.resetRequestFalse();
    if (this.props.teamDetails && Array.isArray(this.props.teamDetails.web_img)) {
      this.setState({ imagesLoaded: true });
    }
    this.intervalId = setInterval(this.rotateImage, 3500);
  }
  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  rotateImage = () => {
    const { teamDetails } = this.props;
    const { currentImageIndex } = this.state;

    if (teamDetails && Array.isArray(teamDetails.web_img) && teamDetails.web_img.length > 1) {
      this.setState({
        currentImageIndex: (currentImageIndex + 1) % teamDetails.web_img.length,
      });
    }
  };

  render() {
    const { teamDetails } = this.props;
    const { currentImageIndex, imagesLoaded } = this.state;



    const backgroundImage = imagesLoaded && teamDetails && Array.isArray(teamDetails.web_img) && teamDetails.web_img.length > 0
      ? `url(${teamDetails.web_img[currentImageIndex].blobUrl})`
      :  'linear-gradient(90deg, #0380b3 0%, #00ffc6 50%, #00acf1 100%)';

    return (
      <>
        <div
          id="customlogin"
          style={{
            backgroundImage: backgroundImage,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100%',
            height: '100vh',
          }}
        >
          <div className="image-topright" />
          <div className="container-fluid">
            <div style={{ display: 'flex' }}>
              <div className="content-login"></div>
              <div style={{ width: '35%' }}>
                <div className="card1">
                  <OtpCard {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  teamDetails: state.reportReducer.teamDetails
});

const mapDispatchToProps = {
  resetRequestFalse,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(customOtp));
