import React from 'react';
import { Input } from '@material-ui/core';
import { formatInput } from './helper';

const renderImage = (item: any) => {
  return (
    <div className="catalog-modal-item-label ne-catalog-modal-item-label">
      {item._mo_product_image ? (
        <img
          src={`https://drive.google.com/thumbnail?id=${item._mo_product_image}`}
          alt="Product-image"
          className="pdt-img"
        />
      ) : (
        <i className="fas fa-camera-retro pdt-icon" />
      )}
    </div>
  );
};

const inputElement = (props: any, item: any) => {
  return (
    <Input
      type="number"
      value={item['product_quantity'] ? item['product_quantity'] : ''}
      onChange={(event: any) =>
        props.handleChangeProductQty(
          item.product_code,
          props.index,
          event.target.value
        )
      }
      onBlur={(event: any) => {
        props.handleChangeBlur(
          item.product_code,
          props.index,
          event.target.value
        );
      }}
      onKeyDown={(event: any) =>
        formatInput(event, props.attributes['allow_decimal'])
      }
      placeholder="Qty"
      className={`catalog-qty-input ne-catalog-qty-input`}
    />
  );
};

const CatalogItem = (props: any) => {
  const item: any = props.item;
  return (
    <div
      key={props.index}
      className="catalog-item-wrapper ne-catalog-item-wrapper"
    >
      <div className="catalog-modal-item-wrapper ne-catalog-modal-item-wrapper">
        {renderImage(item)}
        <div className="catalog-modal-item-qty  ne-catalog-modal-item-qty">
          <div
            className="code-name-wrapper"
            style={{
              width: '100%',
            }}
          >
            <p className="ne-pdt-details ne-ellipsis">{item.product_name}</p>
            <p
              className="ne-pdt-details ne-ellipsis-pc"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {item.product_code}
            </p>
          </div>
          <div className="pdt-price-input-wrapper">
            <div className="price-wrapper">
              <p
                className="ne-pdt-details"
                style={{
                  fontSize: '14px',
                }}
              >{`${item.currency} ${item.price_per_unit} /${
                item.unit_of_measure
              } ${item.discount ? ` - ${item.discount}` : ''}`}</p>
            </div>
            {inputElement(props, item)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogItem;
