import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { isDesktop } from './../../../utils/helper';
import '../styles/irctcMenuPrice.css';

function IRCTCMenuPrice() {
  return (
    <div className="menu-price-container">
      <Document
        file={require('../../../assets/pdf/menu_prices.pdf')}
        loading="Please wait! Loading"
      >
        <Page
          pageNumber={1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={isDesktop() ? '1.5' : '1.0'}
        />
      </Document>
      <Document
        file={require('../../../assets/pdf/menu_prices.pdf')}
        loading=""
      >
        <Page
          pageNumber={2}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={isDesktop() ? '1.5' : '1.0'}
        />
      </Document>
      <Document
        file={require('../../../assets/pdf/menu_prices.pdf')}
        loading=""
      >
        <Page
          pageNumber={3}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={isDesktop() ? '1.5' : '1.0'}
        />
      </Document>
      <Document
        file={require('../../../assets/pdf/menu_prices.pdf')}
        loading=""
      >
        <Page
          pageNumber={4}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={isDesktop() ? '1.5' : '1.0'}
        />
      </Document>
    </div>
  );
}
export default IRCTCMenuPrice;
