import { firebaseStorage } from './../../../../fireStoreConfig/fireStore';
import { isNull } from '../../../../utils/helper';
import { ref, deleteObject, getDownloadURL } from 'firebase/storage';

export const deleteDocumentFile = (
  attributes: any,
  filePath: any,
  formRef: any
) => {
  return deleteObject(ref(firebaseStorage, filePath))
    .then(() => {
      const values = attributes['value'] ? attributes['value'] : [];
      const updatedValues: any = [];
      if (!isNull(values)) {
        values.forEach((ele: any) => {
          const eleFilePath = !isNull(ele['file_path']) ? ele['file_path'] : '';
          if (eleFilePath !== filePath) updatedValues.push(ele);
        });
      }
      formRef.onValueChange(attributes['name'], updatedValues);
      return false;
    })
    .catch((err: any) => {
      return false;
    });
};

export const downloadDocument = async (item: any) => {
  const filePath = item['file_path'] ? item['file_path'] : '';

  getDownloadURL(ref(firebaseStorage, filePath))
    .then((url: any) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    })
    .catch(err => {
      console.log(err);
    });
};
