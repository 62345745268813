import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from '../../../actions/types';
import { getAccessToken as accessToken } from '../../userOnBoarding/api/api.userOnboarding';
import { callAPI } from '../../../api/api';
import * as TeamAPI from '../api/api.home';
import { setCustomToken } from './../../../fireStoreConfig/fireStore';
import {
  dateFilterFormater,
  refactorUserDirectoryDesignation,
  taskFilterQuery,
} from './../../../utils/helper';
import * as taskTypes from '../../../actions/taskTypes';

export function* accessTokenForTeam(action: any) {
  const refreshToken = sessionStorage.getItem('refreshToken');
  const getaccessToken: { status: any; data: any } = yield call(
    accessToken,
    {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      activity_type: 'refresh_token',
      team_id: action.payload.teamId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );
  if (
    sessionStorage.getItem('refreshToken') &&
    getaccessToken.data['access_token']
  ) {
    setCustomToken(getaccessToken.data['access_token']);
    sessionStorage.setItem('accessToken', getaccessToken.data['access_token']);
    yield put({
      type: types.ACCESS_TOKEN_FOR_TEAM_SUCCESS,
      payload: getaccessToken.data,
    });
  } else {
    yield put({
      type: types.ACCESS_TOKEN_FOR_TEAM_FAILURE,
      payload: getaccessToken.data,
    });
  }
}

export function* getTeamUserDetails(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    TeamAPI.getTeamUserDetails,
    action.payload,
    types.GET_TEAM_USER_DETAILS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_TEAM_USER_DETAILS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getUserDesignationDetails(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    TeamAPI.getUserDesignationDetails,
    action.payload,
    types.GET_USER_DESIGNATION_DETAILS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_USER_DESIGNATION_DETAILS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getUserDirectoryDesignation(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    TeamAPI.getUserDirectoryDesignation,
    action.payload,
    types.GET_USER_DIRECTORY_DESIGNATION_FAILURE
  );
  if (response) {
    const updatedData = refactorUserDirectoryDesignation(response.data);
    yield put({
      type: types.GET_USER_DIRECTORY_DESIGNATION_SUCCESS,
      payload: updatedData,
    });
  }
}

export function* getTasks(action: any) {
  const taskDay = 'OVERDUE';
  const dateQuery = dateFilterFormater(taskDay);
  const query = `from=${dateQuery.from}&to=${dateQuery.to}`;
  const { pageSize, pageNumber } = action.payload;
  let filter = taskFilterQuery(
    pageSize,
    pageNumber,
    'ASSIGNED_ONLY',
    false,
    'NOTSTARTED',
    query
  );
  const response: { status: any; data: any } = yield callAPI(
    TeamAPI.getTasks,
    { filter },
    taskTypes.GET_TASKS_FAILURE
  );

  if (response) {
    yield put({
      type: taskTypes.GET_TASKS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* homeSaga() {
  yield takeEvery(types.ACCESS_TOKEN_FOR_TEAM_REQUEST, accessTokenForTeam);
  yield takeEvery(types.GET_TEAM_USER_DETAILS_REQUEST, getTeamUserDetails);
  yield takeEvery(
    types.GET_USER_DESIGNATION_DETAILS_REQUEST,
    getUserDesignationDetails
  );
  yield takeEvery(
    types.GET_USER_DIRECTORY_DESIGNATION_REQUEST,
    getUserDirectoryDesignation
  );
  yield takeEvery(taskTypes.GET_TASKS_REQUEST, getTasks);
}
