import React, { Component } from 'react';
import { connect } from 'react-redux';
import Recaptcha from 'react-recaptcha';
import ReactPhoneInput from 'react-phone-input-2';

import {
  getOTPForUserName,
  resetErrorMessage,
  resetGetOtpRequest,
} from '../actions/actions';
import { validatePhone } from '../../../utils/helper';
import SnackbarMessage from '../../../common/snackbarMessage';
import ProgressSpinner from './progressSpinner';
import withRouter from 'withRouter';
import '../styles/loginCard.css';

interface IState {
  isVerified: boolean;
  phone: string;
  message: string;
  open: boolean;
}

interface IProps {
  getOtpSuccess: boolean;
  loginRequest: boolean;
  error: {
    displayMessage: string;
  };
  open: boolean;
  resetGetOtpRequest: () => void;
  resetErrorMessage: () => void;
  getOTPForUserName: (mobileNo: string, type: string, history: any) => void;
  router: any;
}

class MobileLogin extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isVerified: process.env.NODE_ENV === 'development' ? true : false,
      phone: '',
      open: false,
      message: '',
    };
  }

  componentDidUpdate() {
    if (this.props.getOtpSuccess) {
      const currentPath = window.location.pathname;
      if (currentPath.startsWith('/login/')) {
        const companyName = currentPath.split('/')[2]; // Extract the company name from the URL
        this.props.router.navigate(`/otp/${companyName}`);
      } else {
        this.props.router.navigate('/otp');
      }
      this.props.resetGetOtpRequest();
    }
  }

  componentWillUnmount() {
    this.props.resetErrorMessage();
  }

  verifyCallback = (response: object) => {
    if (response) {
      this.setState({
        isVerified: true,
        open: false,
        message: '',
      });
    }
  };

  callback = () => {
    console.log('Done!!!!');
  };

  handleOnChange = (e: any) => {
    const result = e.replace(/[- )(]/g, '');
    this.setState({ phone: result });
  };

  handleClose = () => {
    this.setState({ open: false, message: '' });
    this.props.resetErrorMessage();
  };

  phoneSubmit = (e: any) => {
    e.preventDefault();
    const phoneValidate = validatePhone(this.state.phone);
    if (!phoneValidate) {
      this.setState({
        message: 'Enter valid mobile number',
        open: true,
      });
    } else if (this.state.isVerified && phoneValidate) {
      const mobileNumber = this.state.phone;
      this.props.getOTPForUserName(
        mobileNumber,
        'PHONE',
        this.props.router.navigate
      );
    } else {
      this.setState({
        message: 'Please verify that you are a human!',
        open: true,
      });
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.phoneSubmit}>
          <ReactPhoneInput
            inputProps={{
              autoFocus: true,
            }}
            country={'in'}
            value={this.state.phone}
            onChange={this.handleOnChange}
          />
          {process.env.NODE_ENV === 'development' ? (
            <div />
          ) : (
            <Recaptcha
              sitekey="6Lck6noUAAAAAKhl_U6YGmjDhv7IAK2pH9JDNKsJ"
              render="explicit"
              verifyCallback={(response: any) => this.verifyCallback(response)}
              onloadCallback={this.callback}
            />
          )}
          <div style={{ paddingTop: 10 }} />
          <button
            type="submit"
            className="btn btn-primary submit-button"
            disabled={this.state.phone.length !== 12 || this.props.loginRequest}
          >
            {this.props.loginRequest ? (
              <ProgressSpinner />
            ) : (
              <React.Fragment>
                {' '}
                Get OTP
                <i className="fas fa-chevron-right paddingLeft-20" />
              </React.Fragment>
            )}
          </button>
        </form>
        <SnackbarMessage
          handleClose={this.handleClose}
          message={this.props.error.displayMessage || this.state.message}
          open={this.props.open || this.state.open}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    error: state.loginReducer.error,
    getOtpSuccess: state.loginReducer.getOtpSuccess,
    open: state.loginReducer.open,
    loginRequest: state.loginReducer.loginRequest,
  };
};

const mapDispatchToProps = {
  getOTPForUserName,
  resetErrorMessage,
  resetGetOtpRequest,
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobileLogin));
