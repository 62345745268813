import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import initStore from './store';
import bugsnagClient from './bugsnag/config';
import ErrorScreen from './error/errorScreen';
import 'react-phone-input-2/lib/style.css';
import './index.css';
// import * as serviceWorker from './serviceWorker';

const store = initStore({});
const ErrorBoundary = bugsnagClient.getPlugin('react');
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ErrorBoundary FallbackComponent={ErrorScreen}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
