import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Initialize Cloud Firestore through Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const firebaseApp = initializeApp(firebaseConfig);

export const setCustomToken = (token: any) => {
  if (token) {
    const auth = getAuth();
    signInWithCustomToken(auth, token)
      .then(userCredential => {})
      .catch(error => {
        throw error;
      });
  }
};

export const db = getFirestore(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);
