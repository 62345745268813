import EndPointConfig from '../../../utils/endPointConfig';

interface IData {
  userName: string;
  loginType: string;
  requestId: string;
  otp: number;
}

export async function getOTPForUserName(data: IData, header: any) {
  const url = EndPointConfig.UserOnboarding.postPasswordLessChanllenge;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        user_name: data.userName,
        user_name_type: data.loginType,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function reRequestOTPForUser(data: IData, header: any) {
  const url = EndPointConfig.UserOnboarding.resendOTP;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        request_id: data.requestId,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function validateOTP(data: IData, header: any) {
  const url = EndPointConfig.UserOnboarding.validateOTP;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        request_id: data.requestId,
        otp_code: data.otp,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getAccessToken(data: any, header: any): Promise<object> {
  const url = EndPointConfig.UserOnboarding.token;
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        app_id: process.env.REACT_APP_APPLICATION_ID,
        user_agent: navigator.userAgent,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function newRefreshToken(data: any, header: any) {
  const url = EndPointConfig.UserOnboarding.newRefreshToken;

  try {
    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        user_id: data.userId,
      }),
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getSWRIDCard(data: any, header: any) {
  let url = EndPointConfig.UserOnboarding.getSwrIdCard;
  if (data.isFromIrctc) {
    url = EndPointConfig.UserOnboarding.getIRCTCIdCard;
  }
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        instance_id: data.id,
      }),
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.text(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}
