import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetRequestFalse } from '../actions/actions';
import EmailLogin from '../components/emailLogin';
import MobileLogin from '../components/mobileLogin';
import '../styles/customLogin.css';
import withRouter from 'withRouter';
import { bindActionCreators, Dispatch } from 'redux';
import { setCustomToken } from 'fireStoreConfig/fireStore';
import * as types from '../../../actions/types';
import * as teamTypes from '../../../actions/teamTypes';
import { accessTokenForGuestUser, getTeamDetails } from 'feature/workflow/actions/report';

interface IState {
  emailMobile: boolean;
  currentImageIndex: number;
  imagesLoaded: boolean;
}

interface IProps {
  resetRequestFalse: () => void;
  dispatch: Dispatch;
  isFromGuestUser: boolean;
  guestUserAccessTokenSuccess: boolean;
  accessTokenForGuestUser: (teamId: string, fromGuestUser: boolean) => void;
  guestUserAccessToken: any;
  guestTeamId: string;
  guestTemplateId: string;
  guestUserAccessTokenFailure: boolean;
  getTeamDetails: (teamId: string) => void;
  teamDetails: any;
  teamDetailsSuccess: boolean;
  teamDetailsReq: boolean;
  teamDetailsFailure: boolean;
  router: any;
}

class CustomLogin extends Component<IProps, IState> {
  private intervalId: NodeJS.Timeout | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      emailMobile: false,
      currentImageIndex: 0,
      imagesLoaded: false,
    };
  }

  componentDidMount() {
    this.props.resetRequestFalse();
    this.props.accessTokenForGuestUser(this.props.router.params.teamName, true);
    this.intervalId = setInterval(this.rotateImage, 5000);
   
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  componentDidUpdate() {

    if (this.props.teamDetails && this.props.teamDetails.show_web_img === false) {
      this.props.router.navigate('/login');
      return;
    }
    if (this.props.guestUserAccessTokenSuccess) {
      this.props.dispatch({
        type: types.RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
      });
      setCustomToken(sessionStorage.getItem('accessToken'));
    }

    if (this.props.guestUserAccessTokenFailure) {
      this.props.dispatch({
        type: types.RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
      });
    }

    if (this.props.teamDetailsSuccess || this.props.teamDetailsFailure) {
      this.props.dispatch({
        type: teamTypes.RESET_GET_TEAM_DETAILS_REQUEST,
      });
      this.setState({ imagesLoaded: true });
    }
    if (!this.props.guestUserAccessToken && !this.props.guestUserAccessTokenSuccess && !this.props.guestUserAccessTokenFailure) {
      this.props.router.navigate('/login');
      return;
    }
     // Navigate to /login if show_img is false
  
  }

  rotateImage = () => {
    const { teamDetails } = this.props;
    const { currentImageIndex } = this.state;

    if (teamDetails && Array.isArray(teamDetails.web_img) && teamDetails.web_img.length > 1) {
      this.setState({
        currentImageIndex: (currentImageIndex + 1) % teamDetails.web_img.length,
      });
    }
  };

  changeValue = (value: boolean) => {
    this.setState({ emailMobile: value });
  };


  render() {
    const { teamDetails } = this.props;
    const { currentImageIndex, imagesLoaded } = this.state;
    if (!teamDetails || !Object.keys(teamDetails).length) {
      return (
        <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
          background: 'linear-gradient(270deg, rgba(3, 245, 151, 0.5) 0%, rgba(0, 129, 255, 0.5) 100%)',
        }}
        >
          <svg
            className="ip"
            viewBox="0 0 256 128"
            width="256px"
            height="128px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              {/* Theme Gradient */}
              <linearGradient id="themeGradient" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#0380b3" /> {/* Blue */}
                <stop offset="50%" stopColor="#00ffc6" /> {/* Cyan */}
                <stop offset="100%" stopColor="#00acf1" /> {/* Light Blue */}
              </linearGradient>
            </defs>
            <g fill="none" strokeLinecap="round" strokeWidth="16">
              <g className="ip__track" stroke="#ddd">
                <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
                <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
              </g>
              <g strokeDasharray="180 656">
                <path
                  className="ip__worm1"
                  stroke="url(#themeGradient)"
                  strokeDashoffset="0"
                  d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
                />
                <path
                  className="ip__worm2"
                  stroke="url(#themeGradient)"
                  strokeDashoffset="358"
                  d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
                />
              </g>
            </g>
          </svg>
        </div>
      );
    }
    
    const backgroundImage =
    imagesLoaded && teamDetails && Array.isArray(teamDetails.web_img) && teamDetails.web_img.length > 0
      ? `url(${teamDetails.web_img[currentImageIndex].blobUrl})`
      :  'linear-gradient(90deg, #0380b3 0%, #00ffc6 50%, #00acf1 100%)';
    return (
      
      <>
        <div
          id="customlogin"
          style={{
            backgroundImage: backgroundImage,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100%',
            height: '100vh',
          }}
        >
          <div className="image-topright" />
          <div className="container-fluid">
            <div style={{ display: 'flex' }}>
              <div className="content-login"></div>
              <div style={{ width: '35%' }}>
                <div className="card1">
                  <div className="logincard">
                    <div className="powered-by">
                      <img
                        src={require('../../../assets/images/userOnBoarding/logo-big.png')}
                        alt="logo"
                      />
                    </div>
                    <div className="content">
                      <h2>Sign into your Team</h2>
                      <div className="login-input-title">
                        <span
                          className={this.state.emailMobile ? '' : 'active'}
                          onClick={() => this.changeValue(false)}
                        >
                          Mobile
                        </span>
                        <span>|</span>
                        <span
                          className={this.state.emailMobile ? 'active' : ''}
                          onClick={() => this.changeValue(true)}
                        >
                          Email
                        </span>
                      </div>
                      {this.state.emailMobile ? (
                        <EmailLogin {...this.props} />
                      ) : (
                        <MobileLogin {...this.props} />
                      )}
                      <p>
                        By going forward you agree to our{' '}
                        <a
                          style={{ color: '#00ACF1' }}
                          href="https://mobileoffize.com/termsofuse"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          terms of service
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  guestUserAccessTokenSuccess: state.reportReducer.guestUserAccessTokenSuccess,
  guestUserAccessToken: state.reportReducer.guestUserAccessToken,
  guestTeamId: state.reportReducer.guestTeamId,
  guestUserAccessTokenFailure: state.reportReducer.guestUserAccessTokenFailure,
  teamDetails: state.reportReducer.teamDetails,
  teamDetailsSuccess: state.reportReducer.teamDetailsSuccess,
  teamDetailsReq: state.reportReducer.teamDetailsReq,
  teamDetailsFailure: state.reportReducer.teamDetailsFailure,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  resetRequestFalse,
  ...bindActionCreators(
    {
      accessTokenForGuestUser,
      getTeamDetails,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomLogin));
