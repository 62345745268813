import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  // Checkbox,
  TableFooter,
  TablePagination,
  TableHead,
  withStyles,
  Badge,
  Tooltip,
} from '@material-ui/core';
import { Classes } from 'jss';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';

import {
  isNull,
  getInboxViewConfig,
  getFormatedInboxData,
} from '../../../../utils/helper';
import {
  filterQuery,
  getLocalStorageTabs,
  getInstanceIdData,
  getBulletFieldData,
} from '../../component/helper';
import SnackbarMessage from '../../../../common/snackbarMessage';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../userOnBoarding/actions/actions';
import {
  getUnassignedData,
  getReportDetails,
  getAllInboxConfigs,
  setActiveUnassignedStage,
  setUnassignedSearchText,
} from '../../actions/report';
import {
  getTeamUserDetails,
  getUserDesignationDetails,
} from '../../actions/home';
import FilterData from '../../component/filterData';
import SearchData from '../../component/searchData';
import * as types from '../../../../actions/types';
import Spinner from '../../../../common/spinner';
import '../../styles/inbox/inbox.css';
import withRouter from 'withRouter';

const inboxViews = [
  'bullet_field',
  'primary_field',
  'secondary_field',
  'additional_field',
  'aggregate_field_2',
  'aggregate_field_1',
];

interface IState {
  page: number;
  rowsPerPage: number;
  headers: any;
  unassignedData: any;
  openFilter: boolean;
  searchText: string;
  filterCount: number;
  allInboxConfigs: any;
  activeStage: any;
  allStages: any;
  showClearButton: boolean;
}

interface IProps {
  reportDetails: any;
  getReportDetailsSuccess: boolean;
  reportDetailsSpinner: boolean;
  unassignedDataSuccess: boolean;
  unassignedDataSpinner: boolean;
  unassignedData: any;
  classes: Classes;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  getUnassignedData: (
    templateId: string,
    queryString: any,
    stageName: string,
    offset: any,
    pageSize: any
  ) => void;
  getReportDetails: (templateId: string, teamId: string, resolve: any) => void;
  getAllInboxConfigs: (templateId: string) => void;
  allInboxConfigReqSuccess: boolean;
  allInboxConfigs: any;
  inboxConfigSpinner: boolean;
  unassignedDataTotalItems: any;
  userTeamDetails: any;
  teamUserDetailsSuccess: boolean;
  getTeamUserDetails: (teamId: string, userId: any) => void;
  userDesignationDetails: any;
  userDesignationDetailsReqSuccess: boolean;
  getUserDesignationDetails: any;
  unassignedActiveStage: any;
  unassignedSearchText: any;
  setActiveUnassignedStage: any;
  setUnassignedSearchText: any;
  router: any;
}

const styles = {
  badge: {
    backgroundColor: '#4a90e2',
    color: '#ffffff',
  },
  root: {
    marginRight: 35,
  },
  switchLabel: {
    color: '#4a4a4a',
    fontSize: 14,
    margin: 0,
  },
};

class Open extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 25,
      headers: [],
      unassignedData: [],
      openFilter: true,
      searchText: '',
      filterCount: 0,
      allInboxConfigs: [],
      activeStage: null,
      allStages: [],
      showClearButton: false,
    };
  }

  /**
   * Check filterObj exists in local storage.
   * If true then parse filterObj and check if template exists, if exists then generate the query
   */
  componentDidMount() {
    this.props.getTeamUserDetails(
      this.props.router.params.teamId,
      sessionStorage.getItem('userId')
    );
  }

  componentDidUpdate() {
    if (this.props.teamUserDetailsSuccess) {
      if (
        !isNull(this.props.userTeamDetails) &&
        !isNull(this.props.userTeamDetails['designation']) &&
        !isNull(this.props.userTeamDetails['designation']['designation_id'])
      ) {
        this.props.getUserDesignationDetails(
          this.props.userTeamDetails['designation']['designation_id']
        );
      }
      this.props.dispatch({
        type: types.RESET_GET_TEAM_USER_DETAILS_REQUEST,
      });
    }

    if (this.props.userDesignationDetailsReqSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_USER_DESIGNATION_DETAILS_REQUEST,
      });
      this.loadData();
    }

    if (this.props.getReportDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_REPORT_DETAILS_REQUEST,
      });
      this.initializeStages();
    }

    if (this.props.unassignedDataSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_UNASSIGNED_DATA_REQUEST,
      });
      this.initUnassignedData(this.props.unassignedData);
    }

    if (this.props.allInboxConfigReqSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_ALL_INBOX_CONFIGS_REQUEST,
      });
      this.generateHeader(this.props.allInboxConfigs, this.props.reportDetails);
    }
  }

  /**
   * Get user group ids
   */
  getUserGroupsIds = () => {
    const groups: any = [];
    if (
      !isNull(this.props.userDesignationDetails) &&
      !isNull(this.props.userDesignationDetails['groups'])
    ) {
      this.props.userDesignationDetails['groups'].forEach((element: any) => {
        groups.push(element['value']);
      });
    }
    return groups;
  };

  initializeStages = () => {
    const reportDetails = !isNull(this.props.reportDetails)
      ? this.props.reportDetails
      : null;
    const allStages = [];
    if (!isNull(reportDetails) && !isNull(reportDetails['template'])) {
      if (
        reportDetails.default_assignment &&
        reportDetails.default_assignment.toLocaleLowerCase() === 'custom'
      ) {
        const templateString = reportDetails.template;
        const template = JSON.parse(templateString);
        const detailsView =
          template && template.views && template.views.details
            ? template.views.details
            : null;
        const secondaryView = !isNull(detailsView) ? detailsView.secondary : [];
        secondaryView.forEach((item: any) => {
          const permissions =
            item.permissions && item.permissions.update
              ? item.permissions.update
              : [];
          const designationGroups = this.getUserGroupsIds();
          const isDesignationExists = permissions.some(
            (r: any) => designationGroups.indexOf(r) >= 0
          );
          if (isDesignationExists)
            allStages.push({
              name: item.name,
              label: item.display_name,
            });
        });
      } else if (
        reportDetails.default_assignment &&
        reportDetails.default_assignment.toLocaleLowerCase() === 'pool'
      ) {
        allStages.push({
          name: 'all',
          label: 'All',
        });
      }
    }
    const activeStage = !isNull(this.props.unassignedActiveStage)
      ? this.props.unassignedActiveStage
      : !isNull(allStages)
      ? allStages[0]
      : null;

    this.setState({ allStages, activeStage }, () => {
      this.props.getAllInboxConfigs(this.props.router.params.templateId);
    });
  };

  getActiveStageName = () => {
    const activeStage = this.state.activeStage;
    return !isNull(activeStage) ? activeStage.name : '';
  };

  getUnassignedDataReq = (queryString: any) => {
    const stageName = this.getActiveStageName();
    this.props.getUnassignedData(
      this.props.router.params.templateId,
      queryString,
      stageName,
      this.state.page,
      this.state.rowsPerPage
    );
  };

  getUpdatedQueryString = (queryString: any) => {
    let updatedQueryString = '';
    if (!isNull(this.state.searchText)) {
      const searchQuery = `_ts_=${this.state.searchText}`;
      updatedQueryString = `${searchQuery}`;
    }
    if (!isNull(queryString)) {
      updatedQueryString = `${queryString}&${updatedQueryString}`;
    }
    return updatedQueryString;
  };

  getLocalStorageQuery = (reportDetails: any) => {
    let queryString = '';
    const fields =
      reportDetails &&
      reportDetails.template &&
      JSON.parse(reportDetails.template).fields;
    const tabs = getLocalStorageTabs(
      fields,
      this.props.router.params.templateId
    );
    queryString = filterQuery(tabs);
    const count = tabs.filter(
      (item: any) => item && item.value && item.value.length !== 0
    );
    this.setState({ filterCount: count.length });
    this.getUnassignedDataReq(this.getUpdatedQueryString(queryString));
  };

  /**
   * get report details
   */
  loadData() {
    return new Promise((resolve) => {
      this.props.getReportDetails(
        this.props.router.params.templateId,
        this.props.router.params.teamId,
        resolve
      );
    });
  }

  generateHeader = (allInboxConfigs: any, report: any) => {
    const headers: any = [];
    const inboxView = getInboxViewConfig(allInboxConfigs, '');
    const fields =
      Object.keys(report).length > 0 &&
      !isNull(report.template) &&
      JSON.parse(report.template).fields;
    if (!isNull(inboxView)) {
      inboxViews.map((item: any) => {
        if (inboxView[item] && inboxView[item]['value']) {
          headers.push(
            this.getHeaderValue(
              fields,
              inboxView[item]['label'],
              inboxView[item]['value']
            )
          );
        } else {
          headers.push({ label: 'NA' });
        }
        return null;
      });
    }

    this.setState(
      {
        headers,
        allInboxConfigs,
        searchText: !isNull(this.props.unassignedSearchText)
          ? this.props.unassignedSearchText
          : '',
      },
      () => {
        this.getLocalStorageQuery(this.props.reportDetails);
      }
    );
  };

  getHeaderValue = (fields: any, label: any, value: any) => {
    if (value.length > 1) {
      if (label) {
        return { label: label };
      } else {
        const fieldName = value.replace('{{', '').replace('}}', '').split('.');
        if (fieldName.length > 1) {
          if (fieldName[fieldName.length - 1] === '_mo_created_at')
            return { label: 'Created at' };
          else if (fieldName[fieldName.length - 1] === '_mo_updated_at')
            return { label: 'Updated at' };
          else {
            const fieldLabel = fields.filter(
              (item: any) => item.name === fieldName[fieldName.length - 1]
            );
            return fieldLabel.length > 0
              ? { label: fieldLabel[0].label }
              : { label: fieldName[fieldName.length - 1] };
          }
        }
      }
    }
    return { label: 'NA' };
  };

  initUnassignedData = (data: any) => {
    const result = getFormatedInboxData(data, this.state.allInboxConfigs, '');
    const unassignedData = !isNull(result) ? result : [];
    this.setState({ unassignedData });
  };

  getLocationUI = (data: any) => {
    const url =
      !isNull(data) && !isNull(data.lat) && !isNull(data.long)
        ? `http://maps.google.com/maps?q=${data.lat},${data.long}`
        : undefined;
    if (!isNull(url))
      return (
        <a href={url} rel="noopener noreferrer" target="_blank">
          <i
            className="fas fa-map-marker-alt"
            style={{ color: '#35c1b8', fontSize: '20px' }}
          />
        </a>
      );
    else return '-';
  };

  getUnassignedDataValue = (data: any) => {
    if (data['value_type'] === 'location') {
      return this.getLocationUI(data['value']);
    } else if (data['value'] && Array.isArray(data['value'])) {
      const value: any = [];
      data['value'].map((item: any) => {
        if (item['designation'] && item['designation']['designation_name'])
          value.push(item['designation']['designation_name']);
        else value.push(item);
        return null;
      });
      return value.join(',');
    } else {
      const dataValue =
        data && data['value'] && typeof data['value'] !== 'object'
          ? data['value']
          : '-';
      return typeof dataValue === 'number'
        ? dataValue % 1 !== 0
          ? dataValue.toFixed(2)
          : dataValue
        : dataValue;
    }
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
      const filterQueryString = this.getFilterQueryString();
      this.getUnassignedDataReq(this.getUpdatedQueryString(filterQueryString));
      setTimeout(() => {
        const element: any = document.getElementById('workflow-inbox-root');
        element.scrollIntoView({ behavior: 'smooth' });
      }, 2);
    });
  };

  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, () => {
      const filterQueryString = this.getFilterQueryString();
      this.getUnassignedDataReq(this.getUpdatedQueryString(filterQueryString));
      setTimeout(() => {
        const element: any = document.getElementById('workflow-inbox-root');
        element.scrollIntoView({ behavior: 'smooth' });
      }, 2);
    });
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  handleChangeOpenFilter = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  applyFilter = (data: any) => {
    if (data.type === 'apply' && data.queryString !== '') {
      this.setState({ filterCount: data.count, page: 0 }, () => {
        this.getUnassignedDataReq(this.getUpdatedQueryString(data.queryString));
      });
    } else {
      this.setState({ filterCount: 0, page: 0, searchText: '' }, () => {
        this.getUnassignedDataReq('');
      });
    }
  };

  onChangeSearchData = (event: any) => {
    this.setState(
      { searchText: event.target.value, showClearButton: false },
      () => {
        if (this.state.searchText.trim() === '') this.searchData('search');
      }
    );
  };

  getFilterQueryString = () => {
    let filterQueryString = '';
    const filterObj = localStorage.getItem('filterObj') || '';
    let filterFields: any = [];
    if (filterObj.trim()) {
      const filterArray = JSON.parse(filterObj);
      const templateIndex = _.findIndex(filterArray, {
        templateId: this.props.router.params.templateId,
      });
      if (templateIndex >= 0) {
        filterFields = filterArray[templateIndex].filterFields;
      }
    }
    if (filterFields.length > 0) {
      filterQueryString = filterQuery(filterFields);
    }
    return filterQueryString;
  };

  searchData = (data: any) => {
    if (data === 'search' && this.state.searchText.trim() !== '') {
      let queryString = '';
      const filterQueryString = this.getFilterQueryString();
      const searchQuery = `_ts_=${this.state.searchText}`;
      if (filterQueryString.length > 0)
        queryString = `${filterQueryString}&${searchQuery}`;
      else queryString = searchQuery;
      this.setState({ page: 0, showClearButton: true }, () => {
        this.getUnassignedDataReq(queryString);
      });
    } else {
      this.setState({ searchText: '', page: 0, showClearButton: false }, () => {
        const filterQueryString = this.getFilterQueryString();
        this.getUnassignedDataReq(filterQueryString);
      });
    }
  };

  handleChangeStage = (activeStage: any) => {
    this.setState({ activeStage: activeStage, page: 0 }, () => {
      const filterQueryString = this.getFilterQueryString();
      this.getUnassignedDataReq(this.getUpdatedQueryString(filterQueryString));
    });
  };

  render() {
    const returnPath =
      this.props.router.location.state &&
      this.props.router.location.state['returnPath']
        ? this.props.router.location.state['returnPath']
        : 'home';
    const spinner =
      this.props.unassignedDataSpinner ||
      this.props.reportDetailsSpinner ||
      this.props.inboxConfigSpinner;
    const { classes } = this.props;
    return (
      <div id="workflow-inbox-root">
        <div className="workflow-inbox-wrapper">
          <div className="workflow-inbox-header">
            <div className="workflow-inbox-title-wrapper">
              <i
                className="fas fa-arrow-left"
                style={{ paddingRight: '20px' }}
                onClick={() => {
                  this.props.setActiveUnassignedStage(null);
                  this.props.setUnassignedSearchText('');
                  this.props.router.navigate(`/member/${returnPath}`);
                }}
              />
              <Tooltip
                title={
                  this.props.reportDetails && this.props.reportDetails.name
                    ? this.props.reportDetails.name
                    : ''
                }
                placement="bottom-start"
              >
                <p>
                  Open -{' '}
                  {this.props.reportDetails
                    ? this.props.reportDetails.name
                    : ''}
                </p>
              </Tooltip>
            </div>
          </div>
          <div className="views-search-wrapper">
            <div className="views-picky">
              <p>Stage</p>
              <Picky
                options={this.state.allStages}
                value={this.state.activeStage}
                labelKey="label"
                valueKey="name"
                numberDisplayed={0}
                includeFilter={true}
                onChange={(data: any) => this.handleChangeStage(data)}
                dropdownHeight={250}
              />
            </div>
            <div className="workflow-inbox-filter-search">
              <Badge
                badgeContent={this.state.filterCount}
                classes={{ badge: classes.badge, root: classes.root }}
              >
                <i
                  className="fas fa-filter"
                  style={{
                    paddingRight: 5,
                    opacity: isNull(this.state.headers) ? 0.5 : 1,
                    cursor: isNull(this.state.headers) ? 'default' : 'pointer',
                  }}
                  onClick={() => {
                    if (!isNull(this.state.headers))
                      this.handleChangeOpenFilter();
                  }}
                />
              </Badge>
              <SearchData
                search={this.searchData}
                searchText={this.state.searchText}
                onChangeSearchData={this.onChangeSearchData}
                wrapperWidth="215px"
                background="linear-gradient(0deg, #f8f8f8 0%, #ececec 100%)"
                searchInputWidth="170px"
                color="#9b9b9b"
                disabled={isNull(this.state.headers)}
                disabledMsg="Views are not configured yet"
                showClearButton={this.state.showClearButton}
              />
            </div>
          </div>
          {spinner ? <Spinner /> : ''}
          {this.state.openFilter &&
          !isNull(this.state.headers) &&
          Object.keys(this.props.reportDetails).length > 0 ? (
            <FilterData
              template={this.props.reportDetails}
              applyFilter={this.applyFilter}
              templateId={this.props.router.params.templateId}
            />
          ) : null}
          <div className="workflow-inbox-content">
            {!isNull(this.state.headers) ? (
              <Paper className="paper-root overflowX">
                <Table className="table-wrapper">
                  <TableHead className="tabel-head-wrapper">
                    <TableRow>
                      <TableCell padding="checkbox" className="checkbox-color">
                        {/* <Checkbox
                    indeterminate={
                      this.props.data.selected.length > 0 &&
                      this.props.data.selected.length <
                        this.props.data.data.length
                    }
                    checked={
                      this.props.data.selected.length ===
                      this.props.data.data.length
                    }
                    onChange={this.props.handleSelectAllClick}
                    /> */}
                        {this.state.headers[0] && this.state.headers[0].label}
                      </TableCell>
                      {this.state.headers &&
                        this.state.headers
                          .slice(1)
                          .map((data: any, index: number) => {
                            if (data.label !== 'NA')
                              return (
                                <TableCell key={index}>{data.label}</TableCell>
                              );
                            return null;
                          })}
                    </TableRow>
                  </TableHead>
                  <TableBody className="tabel-body">
                    {this.state.unassignedData.length > 0 &&
                      this.state.unassignedData.map(
                        (row: any, index: number) => {
                          return (
                            <TableRow
                              hover={true}
                              role="checkbox"
                              // aria-checked={isSelected}
                              tabIndex={-1}
                              key={index}
                              // selected={isSelected}
                            >
                              <TableCell
                                padding="checkbox"
                                // onClick={(event: any) => console.log(event)}
                              >
                                {/* <Checkbox /> */}
                                <Link
                                  to={`/member/${
                                    this.props.router.params.teamId
                                  }/inbox/${
                                    this.props.router.params.templateId
                                  }/details/${getInstanceIdData(row)}`}
                                  state={{ returnPath: `open` }}
                                  onClick={() => {
                                    this.props.setActiveUnassignedStage(
                                      this.state.activeStage
                                    );
                                    this.props.setUnassignedSearchText(
                                      this.state.searchText
                                    );
                                  }}
                                >
                                  {getBulletFieldData(row)}
                                </Link>
                              </TableCell>
                              <TableCell>
                                {this.getUnassignedDataValue(
                                  row['primary_field']
                                )}
                              </TableCell>
                              {this.state.headers[2] &&
                                this.state.headers[2].label !== 'NA' && (
                                  <TableCell>
                                    {this.getUnassignedDataValue(
                                      row['secondary_field']
                                    )}
                                  </TableCell>
                                )}
                              {this.state.headers[3] &&
                                this.state.headers[3].label !== 'NA' && (
                                  <TableCell>
                                    {this.getUnassignedDataValue(
                                      row['additional_field']
                                    )}
                                  </TableCell>
                                )}
                              {this.state.headers[4] &&
                                this.state.headers[4].label !== 'NA' && (
                                  <TableCell>
                                    {this.getUnassignedDataValue(
                                      row['aggregate_field_2']
                                    )}
                                  </TableCell>
                                )}
                              {this.state.headers[5] &&
                                this.state.headers[5].label !== 'NA' && (
                                  <TableCell>
                                    {this.getUnassignedDataValue(
                                      row['aggregate_field_1']
                                    )}
                                  </TableCell>
                                )}
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                  <TableFooter className="tabel-footer">
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        count={this.props.unassignedDataTotalItems}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            ) : spinner ? null : (
              <p style={{ paddingTop: '100px', textAlign: 'center' }}>
                Views are not configured yet
              </p>
            )}
          </div>
          <SnackbarMessage
            handleClose={this.handleClose}
            message={
              this.props.error.displayMessage ||
              this.props.success.displayMessage
            }
            open={this.props.open}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    getReportDetailsSuccess: state.reportReducer.getReportDetailsSuccess,
    reportDetailsSpinner: state.reportReducer.reportDetailsSpinner,
    reportDetails: state.reportReducer.reportDetails,
    unassignedDataSuccess: state.reportReducer.unassignedDataSuccess,
    unassignedDataSpinner: state.reportReducer.unassignedDataSpinner,
    unassignedData: state.reportReducer.unassignedData,
    allInboxConfigReqSuccess: state.reportReducer.allInboxConfigReqSuccess,
    inboxConfigSpinner: state.reportReducer.inboxConfigSpinner,
    allInboxConfigs: state.reportReducer.allInboxConfigs,
    unassignedDataTotalItems: state.reportReducer.unassignedDataTotalItems,
    userTeamDetails: state.homeReducer.userTeamDetails,
    teamUserDetailsSuccess: state.homeReducer.teamUserDetailsSuccess,
    userDesignationDetails: state.homeReducer.userDesignationDetails,
    userDesignationDetailsReqSuccess:
      state.homeReducer.userDesignationDetailsReqSuccess,
    unassignedActiveStage: state.reportReducer.unassignedActiveStage,
    unassignedSearchText: state.reportReducer.unassignedSearchText,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      getUnassignedData,
      getReportDetails,
      getAllInboxConfigs,
      getTeamUserDetails,
      getUserDesignationDetails,
      setActiveUnassignedStage,
      setUnassignedSearchText,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Open)));
