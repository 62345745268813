import React, { Component } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import * as _ from 'lodash';
import './../../styles/lookup/lookup.css';

const styles: any = {
  root: {
    padding: '0 30px 10px',
    margin: 0,
  },
};

class Checklist extends Component<any, any> {
  wrapperRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      options: [],
      newSelected: [],
    };
  }

  componentDidMount() {
    const attributes = this.props.attributes;
    if (attributes['options']) {
      this.setOptions(attributes['options']);
    }
    if (
      typeof attributes['value'] !== 'undefined' &&
      Array.isArray(attributes['value'])
    ) {
      this.setState({ newSelected: attributes['value'] });
    }
  }

  setOptions = (options: any) => {
    this.setState({ options: options });
  };

  renderHeder = () => {
    const attributes = this.props.attributes;
    return (
      <div
        className="lookup-modal-title-wrapper"
        style={{ marginBottom: '20px' }}
      >
        <div className="title">
          <p>{`${attributes['label']}`}</p>
        </div>
      </div>
    );
  };

  handleAddPressed = () => {
    const { newSelected } = this.state;
    this.props.extProps.updateValue(this.props.attributes.name, newSelected);
    this.props.closeModal();
  };

  handleChange = (item: any) => {
    const attributes = this.props.attributes;
    const newSelected = this.state.newSelected || [];
    if (Array.isArray(newSelected)) {
      const index = _.findIndex(
        newSelected,
        (option: any) => option === item[attributes['primaryKey']]
      );
      if (index === -1) {
        newSelected.push(item[attributes['primaryKey']]);
      } else {
        newSelected.splice(index, 1);
      }
    }

    this.setState({ newSelected: newSelected });
  };

  renderContent = () => {
    const { classes, attributes } = this.props;
    const { options } = this.state;
    const value = this.state.newSelected;
    if (Array.isArray(options) && options.length) {
      return (
        <div style={{ display: 'flex' }}>
          <FormControl component="fieldset" style={{ margin: 3 }}>
            <FormGroup>
              {options.map((item: any) => {
                const foundIndex = _.findIndex(
                  value,
                  (val: any) => val === item[attributes.primaryKey]
                );
                const checked = foundIndex !== -1 ? true : false;

                return (
                  <FormControlLabel
                    key={item[attributes.primaryKey]}
                    classes={{
                      root: classes.root,
                    }}
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={() => this.handleChange(item)}
                        name={item[attributes.labelKey]}
                        color={'primary'}
                      />
                    }
                    label={item[attributes.labelKey]}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </div>
      );
    }
    return null;
  };

  renderFooter = () => {
    return (
      <div
        onClick={this.handleAddPressed}
        className="checklist-modal-title-wrapper"
      >
        <div className="title ">
          <p>{`Add`}</p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="lookup-modal-root">
        <div id="lookup-modal-wrapper">
          {this.renderHeder()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Checklist);
