import EndPointConfig from '../../../utils/endPointConfig';

export async function getTeamUserDetails(data: any, header: any) {
  const url = EndPointConfig.Teams.getTeamUserDetails(data.teamId, data.userId);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getUserDesignationDetails(data: any, header: any) {
  const url = EndPointConfig.Teams.getUserDesignationDetails(
    data.designationId
  );
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getUserDirectoryDesignation(data: any, header: any) {
  const url = EndPointConfig.Teams.getUserDirectoryDesignation(data.config);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getTasks(data: any, header: any) {
  let url = EndPointConfig.Reports.listTasks(data.filter);
  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}
