import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export default function initStore(preloadedState: any) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(middleware),
    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
