import React, { Component } from 'react';
import { connect } from 'react-redux';

import { validateOTP, resendOTP, resetErrorMessage } from '../actions/actions';
import SnackbarMessage from '../../../common/snackbarMessage';
import ProgressSpinner from './progressSpinner';
import withRouter from 'withRouter';
import '../styles/loginCard.css';

interface IState {
  otp: string;
  currentCount: number;
}

interface IProps {
  requestId: string;
  loginRequest: boolean;
  error: {
    displayMessage: string;
  };
  open: boolean;
  resetErrorMessage: () => void;
  validateOTP: (requestId: string, otp: string, history: any) => void;
  resendOTP: (requestId: string) => void;
  router: any;
}

class OtpCard extends Component<IProps, IState> {
  intervalId: any;
  otpInput: any;
  constructor(props: any) {
    super(props);
    this.state = {
      otp: '',
      currentCount: 60,
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  handleClose = () => {
    this.props.resetErrorMessage();
  };

  submit = (e: any) => {
    e.preventDefault();
    if (this.state.otp.length === 4) {
      this.props.validateOTP(
        this.props.requestId,
        this.state.otp,
        this.props.router.navigate
      );
    }
  };

  // handleOpen = () => {
  //   this.setState({ open: true });
  // };

  resendOtp = (e: any) => {
    e.preventDefault();
    this.setState({ currentCount: 60 });
    this.props.resendOTP(this.props.requestId);
  };

  timer() {
    this.setState({
      currentCount: this.state.currentCount - 1,
    });
    if (this.state.currentCount < 1) {
      // clearInterval(this.intervalId);
    }
  }

  onChange = (e: any) => {
    e.preventDefault();
    const pattern = /^[0-9\b]+$/;
    if (e.target.value === '' || pattern.test(e.target.value)) {
      this.setState({ otp: e.target.value });
    }
  };

  render() {

    if (!this.props.requestId) {
      const currentPath = window.location.pathname;
      if (currentPath.startsWith('/otp/')) {
        const companyName = currentPath.split('/')[2];
        this.props.router.navigate(`/login/${companyName}`);
      } else {
        this.props.router.navigate('/');
      }
      return <div />;
    }
    return (
      <div className="logincard">
        <div className="powered-by">
          <img
            src={require('../../../assets/images/userOnBoarding/logo-big.png')}
            alt="logo"
          />
        </div>
        <div className="content otp-placeholder">
          <h2>Sign into your Team</h2>
          <form onSubmit={this.submit}>
            <div className="login-input-title">Verify OTP</div>
            <input
              type="text"
              autoFocus={true}
              className="form-control input-field"
              id="exampleInputotp"
              placeholder="&bull;&bull;&bull;&bull;"
              value={this.state.otp}
              onChange={this.onChange}
              maxLength={4}
              autoComplete="off"
              required={true}
            />
            <div className="under-line" />
            <button
              type="submit"
              className="btn btn-primary submit-button marginTop-20"
              disabled={this.state.otp.length !== 4 || this.props.loginRequest}
            >
              {this.props.loginRequest ? (
                <ProgressSpinner />
              ) : (
                <React.Fragment>
                  {' '}
                  Verify OTP
                  <i className="fas fa-chevron-right paddingLeft-20" />
                </React.Fragment>
              )}
            </button>
          </form>
          {this.state.currentCount > 0 ? (
            <p>
              Didn&apos;t receive the OTP ? Request for a new one in{' '}
              <span style={{ color: '#D0021B' }}>
                {this.state.currentCount} second
              </span>
            </p>
          ) : (
            <p>
              Didn&apos;t receive the OTP ?{' '}
              <span
                style={{ color: '#00ACF1', cursor: 'pointer' }}
                onClick={this.resendOtp}
              >
                Resend OTP
              </span>
            </p>
          )}
        </div>
        <SnackbarMessage
          handleClose={this.handleClose}
          message={this.props.error.displayMessage}
          open={this.props.open}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    requestId: state.loginReducer.requestId,
    error: state.loginReducer.error,
    open: state.loginReducer.open,
    loginRequest: state.loginReducer.loginRequest,
    getAccessTokenSuccess: state.loginReducer.getAccessTokenSuccess,
  };
};

const mapDispatchToProps = {
  validateOTP,
  resendOTP,
  resetErrorMessage,
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OtpCard));
