import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import '../styles/basicUser.css';
import withRouter from 'withRouter';

interface IProps {
  userDetails: any;
  router: any;
}

class BasicUser extends Component<IProps, any> {
  componentDidMount() {
    window.onpopstate = this.onBackButtonEvent;
  }

  onBackButtonEvent = (e: any) => {
    e.preventDefault();
    sessionStorage.clear();
    this.props.router.navigate('/login');
  };

  upgradePlan = () => {
    this.props.router.navigate('/otp');
  };

  render() {
    if (this.props.userDetails === undefined) {
      sessionStorage.clear();
      this.props.router.navigate('/');
      return <div />;
    }
    return (
      <div className="basic-container">
        <div className="upgrade-company-logo">
          <img
            src={require('../../../assets/images/userOnBoarding/logo-big.png')}
            alt="logo"
            className="logo"
          />
        </div>
        <>
          <h1>
            Congratulations on successfully creating your own team on
            MobileOffize
          </h1>
          <h6>Now, upgrade to become a power user</h6>
          <div className="button-wrapper">
            <Button
              className="upgrade-button"
              onClick={() => this.upgradePlan()}
            >
              UPGRADE
            </Button>
          </div>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.loginReducer.userDetails,
  };
};
export default connect<any, any, any>(
  mapStateToProps,
  null
)(withRouter(BasicUser));
