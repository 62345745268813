import React from 'react';
import './../styles/filter.css';
import { Tooltip } from '@material-ui/core';
import { isNull } from 'utils/helper';

const SearchData = (props: any) => {
  return (
    <div
      className="workflow-data-search-wrapper"
      style={{ width: props.wrapperWidth, background: props.background }}
    >
      <Tooltip
        title={
          props.disabled ? (props.disabledMsg ? props.disabledMsg : '') : ''
        }
      >
        <input
          className="workflow-data-search-text"
          style={{ width: props.searchInputWidth, color: props.color }}
          type="text"
          placeholder="Search..."
          value={props.searchText}
          onChange={props.onChangeSearchData}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 && props.searchText.trim() !== '')
              props.search('search');
          }}
          disabled={props.disabled}
        />
      </Tooltip>
      <div
        className={`workflow-data-search-btn ${
          props.disabled ? 'disabled' : ''
        }`}
      >
        {!isNull(props.searchText) && props.showClearButton ? (
          <i className="fas fa-times" onClick={() => props.search('clear')} />
        ) : (
          <i className="fas fa-search" onClick={() => props.search('search')} />
        )}
      </div>
    </div>
  );
};

export default SearchData;
