import React, { Component } from 'react';
import { Picky } from 'react-picky';
import {
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  InputLabel,
  Button,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as _ from 'lodash';

import { filterQuery, getLocalStorageTabs } from './helper';
import 'react-picky/dist/picky.css';
import './../styles/filter.css';
import { isNull } from 'utils/helper';

const dateOptions = [
  'None',
  'Today',
  'Yesterday',
  'Current month',
  'Last 30 days',
  'Last 60 days',
  'Last 90 days',
  'Custom',
];

const defaultFilter = [
  {
    type: 'date',
    name: '_mo_created_at',
    label: 'Created Date',
    value: null,
    options: dateOptions,
  },
  {
    type: 'date',
    name: '_mo_updated_at',
    label: 'Updated Date',
    value: null,
    options: dateOptions,
  },
];

class FilterData extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tabs: [],
      index: 0,
      customDate: false,
    };
  }

  /**
   * Check filterObj exists in local storage.
   * If true then parse filterObj and check if template exists, if exists then store filterFields in local state(tabs) or get default tabs
   */
  componentDidMount() {
    const fields =
      this.props.template &&
      this.props.template.template &&
      JSON.parse(this.props.template.template).fields;
    let tabs: any = [];
    if (fields.length > 0) {
      tabs = getLocalStorageTabs(fields, this.props.templateId);
      if (tabs.length > 0) {
        this.getTabs(fields, tabs);
      } else {
        this.getTabs(fields, [...defaultFilter]);
      }
    }
  }

  componentWillUnmount() {
    this.resetFilterCondition();
  }

  getTabs = (fields: any, existTabs: any) => {
    const tabs: any = existTabs;
    fields &&
      fields.map((tab: any) => {
        if (
          tab.searchable &&
          (tab.type === 'picker' ||
            tab.type === 'status_picker' ||
            tab.type === 'date')
        ) {
          const fieldExistsIndex = _.findIndex(existTabs, { name: tab.name });
          if (fieldExistsIndex < 0) {
            const item = tab;
            if (tab.type === 'date') {
              item['options'] = dateOptions;
              item['value'] = null;
            } else item['value'] = [];
            tabs.push(item);
          }
        }
        return null;
      });
    !isNull(tabs) &&
      tabs.forEach((element: any, index: number) => {
        if (element.type === 'date') {
          tabs[index]['options'] = dateOptions;
        }
      });
    this.setState({ tabs });
  };

  closeCustomDate = () => {
    this.setState({ customDate: false });
  };

  handleChange = (name: string) => (event: any) => {
    const stateCopy: any = Object.assign({}, this.state);
    stateCopy.tabs[stateCopy.index][name] = event;
    this.setState(stateCopy);
  };

  dailogCustomDate = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.customDate}
        className="custom-date-dialog"
      >
        <DialogTitle className="title">Custom Date</DialogTitle>
        <DialogContent>
          <div className="custom-date-wrapper">
            <div className="custom-date-select">
              <InputLabel shrink={true} className="custom-date-select-lable">
                From
              </InputLabel>
              <KeyboardDatePicker
                format="do MMM yyyy"
                value={
                  this.state.tabs[this.state.index] &&
                  this.state.tabs[this.state.index].from
                    ? this.state.tabs[this.state.index].from
                    : moment()
                }
                onChange={this.handleChange('from')}
                animateYearScrolling={false}
                className="date-select"
              />
            </div>
            <div className="custom-date-select">
              <InputLabel shrink={true} className="custom-date-select-lable">
                To
              </InputLabel>
              <KeyboardDatePicker
                format="do MMM yyyy"
                value={
                  this.state.tabs[this.state.index] &&
                  this.state.tabs[this.state.index].to
                    ? this.state.tabs[this.state.index].to
                    : moment()
                }
                onChange={this.handleChange('to')}
                animateYearScrolling={false}
                className="date-select"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="custom-date-select-cancel"
            onClick={this.closeCustomDate}
            variant="outlined"
          >
            Cancel
          </Button>

          <Button
            className="custom-date-select-done"
            onClick={this.closeCustomDate}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  selectDateOption = (name: any) => (value: any) => {
    const stateCopy: any = Object.assign({}, this.state);
    const index = this.state.tabs.findIndex((item: any) => item.name === name);
    if (value === 'Custom') {
      stateCopy.customDate = true;
      stateCopy.index = index;
      stateCopy.tabs[index].value = value;
    } else {
      if (value === 'None') {
        stateCopy.tabs[index].value = null;
      } else {
        stateCopy.tabs[index].value = value;
      }
    }
    this.setState(stateCopy);
  };

  selectStatusOption = (name: any) => (value: any) => {
    const stateCopy: any = Object.assign({}, this.state);
    const index = this.state.tabs.findIndex((item: any) => item.name === name);
    stateCopy.tabs[index].value = value;
    this.setState(stateCopy);
  };

  /**
   * Check filterObj exists in local storage.
   * If true then parse filterObj and check if template exists, if exists then update filterFields or push new object
   */
  getQuery = () => {
    let queryString = '';
    let filterArray: any = [];
    const filterObj = localStorage.getItem('filterObj') || '';
    if (filterObj.trim()) {
      filterArray =
        JSON.parse(filterObj).length > 0 ? JSON.parse(filterObj) : [];

      const teamExistsIndex = _.findIndex(filterArray, {
        templateId: this.props.templateId,
      });
      if (teamExistsIndex >= 0) {
        filterArray[teamExistsIndex].filterFields = this.state.tabs;
      } else {
        filterArray.push({
          templateId: this.props.templateId,
          filterFields: this.state.tabs,
        });
      }
    } else {
      filterArray = [
        {
          templateId: this.props.templateId,
          filterFields: this.state.tabs,
        },
      ];
    }

    const count = this.state.tabs.filter(
      (item: any) => item && item.value && item.value.length !== 0
    );

    if (filterArray.length > 0) {
      const filterStringfy = JSON.stringify(filterArray);
      localStorage.setItem('filterObj', filterStringfy);
    }

    queryString = filterQuery(this.state.tabs);

    this.props.applyFilter({ type: 'apply', queryString, count: count.length });
  };

  resetFilterCondition = () => {
    const stateCopy: any = Object.assign({}, this.state);
    this.state.tabs.map((item: any, index: number) => {
      item.type === 'picker' || item.type === 'status_picker'
        ? (stateCopy.tabs[index].value = [])
        : (stateCopy.tabs[index].value = null);
      return null;
    });
    this.setState(stateCopy);
  };

  /**
   * Check filterObj exists in local storage.
   * If true then parse filterObj and check if template exists, if exists then remove object from local storage
   * If filterObj is empty, then remove filterObj from local storage
   */
  clearFilter = () => {
    this.resetFilterCondition();
    let filterArray: any = [];
    const filterObj = localStorage.getItem('filterObj') || '';
    if (filterObj.trim()) {
      filterArray =
        JSON.parse(filterObj).length > 0 ? JSON.parse(filterObj) : [];
      const deletedElements = _.remove(
        filterArray,
        (item: any) => item.templateId === this.props.templateId
      );
      if (deletedElements.length > 0) {
        const filterStringfy = JSON.stringify(filterArray);
        filterArray.length === 0
          ? localStorage.removeItem('filterObj')
          : localStorage.setItem('filterObj', filterStringfy);
      }
    }
    this.props.applyFilter({ type: 'clear' });
  };

  render() {
    return (
      <div className="filter-data-container">
        <div className="filter-data-tab-wrapper">
          {this.state.tabs &&
            this.state.tabs.map((item: any, index: number) => {
              if (item.type === 'picker' || item.type === 'status_picker') {
                return (
                  <div key={index} className="filter-data-select">
                    <p>{item.label}</p>
                    <Picky
                      options={item.options}
                      value={this.state.tabs[index].value}
                      multiple={true}
                      numberDisplayed={0}
                      includeSelectAll={true}
                      includeFilter={true}
                      onChange={this.selectStatusOption(item.name)}
                      dropdownHeight={250}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} className="filter-data-select">
                    <p>{item.label}</p>
                    <Picky
                      options={item.options}
                      value={this.state.tabs[index].value}
                      multiple={false}
                      includeFilter={true}
                      onChange={this.selectDateOption(item.name)}
                      dropdownHeight={250}
                    />
                  </div>
                );
              }
            })}
        </div>
        <div className="filter-data-buttons-wrapper">
          <Button
            className="filter-data-clear-button"
            onClick={this.clearFilter}
            variant="outlined"
          >
            Clear
          </Button>
          <Button className="filter-data-apply-button" onClick={this.getQuery}>
            Apply
          </Button>
        </div>
        {this.dailogCustomDate()}
      </div>
    );
  }
}

export default FilterData;
