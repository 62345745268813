import moment from 'moment';
import {
  constructDateQueryString,
  dateFilterFormater,
} from './../../../utils/helper';
import * as _ from 'lodash';

const dateOptions = [
  'None',
  'Today',
  'Current month',
  'Last month',
  'Last 3 months',
  'Custom',
];

export const filterQuery = (obj: any) => {
  let queryString = '';

  obj.length > 0 &&
    obj.map((item: any) => {
      const name = item['name'];
      const values = item['value'];
      const type = item['type'];
      let query = '';
      if (values && values.length !== 0) {
        switch (type) {
          case 'date':
            values !== 'Custom'
              ? (query = constructDateQueryString(
                  [dateFilterFormater(values)],
                  name
                ))
              : (query = constructDateQueryString(
                  [
                    {
                      from: item.from
                        ? moment(item.from)
                            .startOf('day')
                            .toISOString()
                        : moment()
                            .startOf('day')
                            .toISOString(),
                      to: item.to
                        ? moment(item.to)
                            .endOf('day')
                            .toISOString()
                        : moment()
                            .endOf('day')
                            .toISOString(),
                      label: values,
                    },
                  ],
                  name
                ));
            break;
          case 'picker':
          case 'status_picker':
            query = `${name}=${values}`;
            break;
          default:
            break;
        }
      }
      // if (queryString.length) {
      //   queryString = query !== '' ? `${queryString}&${query}` : queryString;
      // } else {
      //   queryString = query;
      // }
      queryString.length
        ? query !== ''
          ? (queryString = `${queryString}&${query}`)
          : (queryString = queryString)
        : (queryString = query);
      return null;
    });
  return queryString;
};

/**
 * Modify local storage, if any options changed in template fields
 * Remove if local storage field not found in template fields
 */
export const getLocalStorageTabs = (fields: any, templateId: string) => {
  const filterObj = localStorage.getItem('filterObj') || '';
  let filterFields: any = [];
  const tabs: any = [];
  if (filterObj.trim()) {
    const filterArray = JSON.parse(filterObj);
    const templateIndex = _.findIndex(filterArray, {
      templateId,
    });
    if (templateIndex >= 0) {
      filterFields = filterArray[templateIndex].filterFields;
    }
    if (filterFields.length > 0) {
      filterFields.map((item: any) => {
        const fieldIndex = _.findIndex(fields, { name: item.name });
        if (fieldIndex >= 0) {
          item.options =
            item.type === 'date' ? dateOptions : fields[fieldIndex].options;
          if (item.type === 'picker' || item.type === 'status_picker') {
            const v: any = [];
            item.value.length > 0 &&
              item.value.map((value: any) => {
                if (item.options.includes(value)) {
                  v.push(value);
                }
                return null;
              });
            item.value = v;
          }
          if (item.type === 'date' && !item.options.includes(item.value))
            item.value = null;
          tabs.push(item);
        } else if (
          item.name === '_mo_created_at' ||
          item.name === '_mo_updated_at'
        ) {
          item.options = dateOptions;
          tabs.push(item);
        }
        return null;
      });
    }
    if (tabs.length > 0) {
      filterArray[templateIndex].filterFields = tabs;
      const filterStringfy = JSON.stringify(filterArray);
      localStorage.setItem('filterObj', filterStringfy);
    }
  }
  return tabs;
};

export const getBulletFieldData = (item: any) => {
  return item['bullet_field'] && item['bullet_field']['value']
    ? moment(item['bullet_field']['value']).format('Do MMM YY')
    : 'NA';
};

export const getInstanceIdData = (item: any) => {
  return item['instance_id'] && item['instance_id']['value']
    ? item['instance_id']['value']
    : '';
};
