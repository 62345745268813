const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
const GET_TASKS_FAILURE = 'GET_TASKS_FAILURE';
const RESET_GET_TASKS_REQUEST = 'RESET_GET_TASKS_REQUEST';

export {
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  RESET_GET_TASKS_REQUEST,
};
