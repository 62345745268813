import { call, put } from 'redux-saga/effects';
import bugsnagClient from './../bugsnag/config';
import { decodeAccessToken } from '../utils/helper';
import {
  getAccessToken as accessToken,
  newRefreshToken,
} from './../feature/userOnBoarding/api/api.userOnboarding';
import * as types from './../actions/types';
import { setCustomToken } from 'fireStoreConfig/fireStore';
function headers() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    dataType: 'json',
  };
}

export function* getAccessTokenReq(teamId: string, refreshToken: any) {
  const accessTokenResp: { status: any; data: any } = yield call(
    accessToken,
    {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      activity_type: 'refresh_token',
      team_id: teamId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );
  return accessTokenResp;
}

export function* callAPI(api: any, payload: object, failureType: string) {
  try {
    const accessToken = sessionStorage.getItem('accessToken');
    const header = headers();
    if (accessToken) {
      header['Authorization'] = 'bearer ' + accessToken;
    }
    let response: { status: any; data: any } = yield call(api, payload, header);
    const decodeTokenInfo = accessToken ? decodeAccessToken(accessToken) : null;

    if (
      response &&
      response.data.error &&
      response.data.error.status &&
      response.data.error.status === 401
    ) {
      if (decodeTokenInfo && decodeTokenInfo.aid) {
        let accessTokenResp: { status: any; data: any } = yield call(
          getAccessTokenReq,
          decodeTokenInfo.aid,
          sessionStorage.getItem('refreshToken')
        );
        if (
          accessTokenResp &&
          accessTokenResp.data &&
          accessTokenResp.data['error']
        ) {
          const accessTokenError = accessTokenResp.data['error'];
          const { status, error_code } = accessTokenError;
          if (
            status &&
            status === 400 &&
            error_code &&
            error_code === 'REFRESH_TOKEN_RESET'
          ) {
            const userId = sessionStorage.getItem('userId');
            const refreshTokenResp: { status: any; data: any } = yield call(
              newRefreshToken,
              { userId },
              header
            );
            if (
              refreshTokenResp &&
              refreshTokenResp.status &&
              refreshTokenResp['data']
            ) {
              sessionStorage.setItem(
                'refreshToken',
                refreshTokenResp.data['refresh_token']
              );
              /**
               * Reissue new access token with new refresh token
               */
              accessTokenResp = yield call(
                getAccessTokenReq,
                decodeTokenInfo.aid,
                refreshTokenResp.data['refresh_token']
              );

              yield put({
                type: types.RESET_REFRESH_TOKEN,
                payload: refreshTokenResp.data,
              });
            }
          }
        }

        yield put({
          type: types.ACCESS_TOKEN_FOR_TEAM_SUCCESS,
          payload: accessTokenResp.data,
        });

        setCustomToken(accessTokenResp.data['access_token']);
        sessionStorage.setItem(
          'accessToken',
          accessTokenResp.data['access_token']
        );

        header['Authorization'] =
          'bearer ' + accessTokenResp.data['access_token'];

        response = yield call(api, payload, header);
      } else {
        // clear all localstorage
        sessionStorage.clear();
        // redirect to '/login'
        window.location.href = '/login';
      }
    }

    if (!response.status) {
      const message =
        response.data &&
        response.data.data &&
        response.data.data[0] &&
        response.data.data[0].message
          ? response.data.data[0].message
          : response.data && response.data.message
          ? response.data.message
          : '';

      yield put({ type: failureType, payload: message });
      return null;
    }
    return response;
  } catch (e) {
    bugsnagClient.notify(e);
    yield put({ type: failureType });
  }
  return null;
}

export function* callFirestoreAPI(api: any, payload: any, failureType: any) {
  try {
    const response: { status: any; data: any; error: any } = yield call(
      api,
      payload
    );

    if (!response.status) {
      yield put({ type: failureType, payload: response.error });
      return null;
    }

    return response;
  } catch (e) {
    console.log(e);
    yield put({ type: failureType });
  }
  return null;
}
