import * as types from '../../../actions/types';
import * as taskTypes from '../../../actions/taskTypes';

export function accessTokenForTeam(teamId: string) {
  return {
    type: types.ACCESS_TOKEN_FOR_TEAM_REQUEST,
    payload: {
      teamId,
    },
  };
}

export function getTeamUserDetails(teamId: string, userId: string) {
  return {
    type: types.GET_TEAM_USER_DETAILS_REQUEST,
    payload: {
      teamId,
      userId,
    },
  };
}

export function getUserDesignationDetails(designationId: string) {
  return {
    type: types.GET_USER_DESIGNATION_DETAILS_REQUEST,
    payload: {
      designationId,
    },
  };
}

export function getUserDirectoryDesignation(config: string) {
  return {
    type: types.GET_USER_DIRECTORY_DESIGNATION_REQUEST,
    payload: {
      config,
    },
  };
}

export function getTasks(pageSize: any, pageNumber: any) {
  return {
    type: taskTypes.GET_TASKS_REQUEST,
    payload: { pageSize, pageNumber },
  };
}
