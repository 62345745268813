import React, { Component } from 'react';
import { Modal } from '@material-ui/core';
import { ref, getDownloadURL } from 'firebase/storage';

import Spinner from '../../../../common/spinner';
import { firebaseStorage } from './../../../../fireStoreConfig/fireStore';
import { isNull } from './../../../../utils/helper';
import SnackbarMessage from '../../../../common/snackbarMessage';
import './../../styles/inbox/documentModal.css';

class DocumentPreviewModal extends Component<any, any> {
  wrapperRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      renderSpinner: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  };

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.props.closeModal();
    }
  };

  openInNewTab = (url: any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  getFileDownloadURL = async (item: any) => {
    getDownloadURL(ref(firebaseStorage, item['file_path']))
      .then((url: any) => {
        this.setState({ renderSpinner: false });
        this.openInNewTab(url);
      })
      .catch((error: any) => {
        switch (error.code) {
          case 'storage/object-not-found':
            this.setState({
              renderSpinner: false,
              open: true,
              errMessage: `File doesn't exist`,
            });
            break;
          case 'storage/unauthorized':
            this.setState({
              renderSpinner: false,
              open: true,
              errMessage: `You doesn't have permission to access the object`,
            });
            break;
        }
      });
  };

  handleCloseSnackbar = () => {
    this.setState({ open: false, errMessage: '' });
  };

  render() {
    const props = this.props;
    return (
      <Modal open={props.open}>
        <div id="doc-preview-modal-wrapper">
          <div
            className="doc-preview-modal-container"
            ref={node => (this.wrapperRef = node)}
          >
            <div className="doc-preview-modal-header">
              <p>Document(s)</p>
              <i
                className="far fa-times-circle close-button"
                onClick={() => props.closeModal()}
              />
            </div>
            {this.state.renderSpinner ? <Spinner /> : null}
            <div className="doc-preview-modal-body">
              {!isNull(this.props.data) &&
                this.props.data.map((item: any, index: number) => {
                  return (
                    <p
                      key={index}
                      className="doc-item"
                      onClick={() => {
                        this.setState({ renderSpinner: true });
                        this.getFileDownloadURL(item);
                      }}
                    >
                      {item['name'] ? item['name'] : 'Document'}
                    </p>
                  );
                })}
            </div>
          </div>
          <SnackbarMessage
            handleClose={this.handleCloseSnackbar}
            message={this.state.errMessage}
            open={this.state.open}
          />
        </div>
      </Modal>
    );
  }
}

export default DocumentPreviewModal;
