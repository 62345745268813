import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';

const Spinner = () => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 99999,
      transform: 'translate(-50%,-50%,0)',
    }}
  >
    <CircularProgress size="80px" disableShrink={true} thickness={2.5} />
  </div>
);

export default withStyles({})(Spinner);
