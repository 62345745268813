import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Login from './feature/userOnBoarding/pages/login';
import Otp from './feature/userOnBoarding/pages/otp';
import Info from './feature/userOnBoarding/pages/info';
import SWRIDCard from './feature/userOnBoarding/pages/swrIdCard';
import VendorOnboardingForm from './feature/workflow/pages/form/vendorOnboardingForm';
import VendorOnboardingSuccessForm from './feature/workflow/pages/form/vendorOnboardingSuccessForm';
import GuestUserForm from './feature/workflow/pages/form/guestUser';
import MemberRoutes from './memberRoutes';
import GuestUserSuccess from './feature/workflow/pages/form/guestUser/success';
import NaveenEntForm from './feature/workflow/pages/form/NaveenEnterprices';
import NaveenEntStoreOrderForm from './feature/workflow/pages/form/NaveenEnterprices/storeOrder';
import NaveenEntUpdateForm from './feature/workflow/pages/form/NaveenEnterprices/updateForm';
import NaveenEntSummary from './feature/workflow/pages/form/NaveenEnterprices/summary';
import NaveenEntSuccess from './feature/workflow/pages/form/NaveenEnterprices/success';
import RatingSuccess from './feature/workflow/pages/form/rating/success';
import RatingForm from './feature/workflow/pages/form/rating';
import IRCTCSummary from './feature/workflow/pages/form/irctc/summary';
import IRCTCSuccess from './feature/workflow/pages/form/irctc/success';
import IRCTCMenuPrice from './feature/userOnBoarding/pages/irctcMenuPrice';
import '../node_modules/react-owl-carousel2/src/owl.carousel.css';
import '../node_modules/react-owl-carousel2/src/owl.theme.default.css';
import './App.css';
import CustomLogin from 'feature/userOnBoarding/pages/customLogin';
import CustomOtp from 'feature/userOnBoarding/pages/customOtp';

class App extends Component {
  componentDidMount() {
    loadCSS('https://pro.fontawesome.com/releases/v5.11.2/css/all.css');
  }
  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/login/:teamName" element={<CustomLogin />} />
            <Route path="/otp/:teamName" element={<CustomOtp />} />
            <Route path="/auth/:userType" element={<Info />} />
            <Route path="/p/vcard/swr" element={<SWRIDCard />} />
            <Route path="/p/vcard/irctc" element={<SWRIDCard />} />
            <Route
              path="/vendoronboarding"
              element={<VendorOnboardingForm />}
            />
            <Route
              path="/vendor/:vendorname/success"
              element={<VendorOnboardingSuccessForm />}
            />
            <Route path="/public/:publicId" element={<GuestUserForm />} />
            <Route path="/guest/success" element={<GuestUserSuccess />} />
            <Route
              path="/p/naveenenterprises/:publicId"
              element={<NaveenEntForm />}
            />
            <Route
              path="/p/naveenenterprises/s/:publicId"
              element={<NaveenEntStoreOrderForm />}
            />
            <Route
              path="/p/naveenenterprises/:publicId/update"
              element={<NaveenEntUpdateForm />}
            />
            <Route
              path="/p/naveenenterprises/:publicId/summary"
              element={<NaveenEntSummary />}
            />
            <Route
              path="/p/naveenenterprises/:publicId/success"
              element={<NaveenEntSuccess />}
            />
            <Route path="/p/r/:publicId/:instanceId" element={<RatingForm />} />

            <Route
              path="/p/r/:publicId/:instanceId/success"
              element={<RatingSuccess />}
            />
            <Route
              path="/p/irctc/smartid/:publicId/:desigId"
              element={<IRCTCSummary />}
            />
            <Route
              path="/p/irctc/smartid/:publicId/:desigId/success"
              element={<IRCTCSuccess />}
            />
            <Route path="/irctc/price" element={<IRCTCMenuPrice />} />
            <Route path="/member/*" element={<MemberRoutes />} />
          </Routes>
        </Router>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
