import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import classNames from 'classnames';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Icon,
  ListItemIcon,
  withStyles,
} from '@material-ui/core';
import { Classes } from 'jss';

import Header from './header';
import './../styles/drawer.css';
import withRouter from 'withRouter';

const drawerWidth = 285;
const styles: any = (theme: any) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // backgroundColor: '#6471de',
  },
  drawerClose: {
    backgroundColor: '#6471de',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 100,
  },
  iconRoot: {
    height: '35px',
    minWidth: '35px',
    borderRadius: '50%',
    fontSize: '20px',
    opacity: 0.5,
    border: '2px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    '&$active': {
      opacity: 1,
      color: '#6471DE',
      backgroundColor: '#FFFFFF',
    },
  },
  active: {},
  routeText: {
    color: '#FFFFFF',
    fontSize: 16,
    paddingLeft: 20,
  },
  listItemRoot: {
    height: '60px',
    alignItems: 'flex-start',
    padding: '0 0 0 20px',
  },
  listRoot: {
    height: 'calc(100% - 95px)',
    backgroundColor: '#424C9F',
    paddingTop: '20px',
    position: 'relative',
  },
  openIcon: {
    padding: '0 0 25px 0',
    display: 'flex',
    justifyContent: 'center',
  },
});

const teamNav = [
  { text: 'Home', path: 'home', icon: 'far fa-home' },
  // { text: 'Chat', path: 'chat', icon: 'far fa-comment-alt' },
  // { text: 'Task', path: 'task', icon: 'far fa-calendar-check' },
  // { text: 'Reports', path: 'reports', icon: 'far fa-chart-area' },
  // { text: 'Know', path: 'know', icon: 'far fa-book' },
];

interface IState {
  open: boolean;
  teamOpen: boolean;
}

interface IProps {
  classes: Classes;
  children: any;
  router: any;
}

class MemberDrawer extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: true,
      teamOpen: false,
    };
  }

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  onChangeTeam = () => {
    this.setState({ teamOpen: !this.state.teamOpen });
  };

  render() {
    const { classes } = this.props;
    const open = this.state.open;

    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <Header open={open} onChangeTeam={this.onChangeTeam} />
          {this.state.teamOpen ? (
            ''
          ) : (
            <List classes={{ root: classes.listRoot }}>
              {teamNav.map((obj: any, index: number) => (
                <NavLink
                  to={`/member/${obj.path}`}
                  key={index}
                  style={{ textDecoration: 'none' }}
                >
                  <ListItem
                    button
                    key={obj.text}
                    disableGutters={true}
                    className={!open ? classes.openIcon : ''}
                    classes={{ root: classes.listItemRoot }}
                  >
                    <ListItemIcon
                      className={classNames(
                        classes.iconRoot,
                        obj.path ===
                          this.props.router.location.pathname.split('/')[2]
                          ? classes.active
                          : ''
                      )}
                    >
                      <Icon
                        className={`${obj.icon}`}
                        style={{ fontSize: 15 }}
                      />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText
                        primary={obj.text}
                        classes={{
                          primary: classes.routeText,
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </ListItem>
                </NavLink>
              ))}
              <img
                src={require('./../../../assets/images/logo-white.png')}
                alt="logo"
                style={{
                  height: '14px',
                  paddingLeft: '30px',
                  position: 'absolute',
                  bottom: '20px',
                }}
              />
            </List>
          )}
        </Drawer>
        {open ? (
          <div className="toggle-icon" onClick={() => this.handleDrawerClose()}>
            <Icon className={`far fa-angle-left`} />
          </div>
        ) : (
          <div className="toggle-icon" onClick={() => this.handleDrawerOpen()}>
            <Icon className={`far fa-angle-right`} />
          </div>
        )}
        <main
          style={{
            // paddingLeft: '26px',
            width: '100%',
            height: '100vh',
            // overflow: 'auto',
          }}
        >
          <React.Fragment>{<Outlet />}</React.Fragment>
        </main>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(MemberDrawer))
);
