import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag({
  apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
  notifyReleaseStages: ['production'],
});
bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
